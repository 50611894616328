import React, { useMemo } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { useEventCampaignProductsTracking, useViewPageTracking } from "../thirdParties/gtm/tagManager"
import { isWysiwygEmpty } from "../../utils/wysiwyg"
import CampaignBanner from "./campaignBanner.component"
import { useTranslation } from "../translation/translate.component"
import DynamicBlock from "../../components/block/dynamicBlock.component"

export default function MultipleCampaignPage ({
  data: {
    campaign: { bannerImg, bannerTemplate, bannerBgColor, name, campaignHook, landingPageProducts, coveritsVariant },
  },
}) {
  const { t } = useTranslation()
  const products = useMemo(() => {
    return landingPageProducts.map((landingPageProduct, i) => ({
      ...landingPageProduct,
      impactProduct: {
        ...landingPageProduct.impactProduct,
        variants: [coveritsVariant[i]],
      },
    }))
  }, [landingPageProducts])

  useViewPageTracking(`Campagnes`, name, `Landing Multi produits`)
  useEventCampaignProductsTracking(`Landing Multi produits - ${name}`, products)

  return (
    <>
      {(bannerImg || !isWysiwygEmpty(campaignHook)) && (
        <CampaignBanner
          template={bannerTemplate}
          bannerImg={bannerImg}
          description={campaignHook}
          bannerBgColor={bannerBgColor}
          withCta={false}
        />
      )}
      <DynamicBlock __component="VerifiedReviews" />

      {products.length > 4 && (
        <DynamicBlock
          __component="ProductFinder"
          blockTitle={`${t(`common:product.product_finder_title`)} :`}
          blockTitleSeo="h2"
          products={products}
        />
      )}

      {products.length <= 4 && (
        <DynamicBlock
          __component="ProductBlock"
          data-testid="product_block_campaign"
          blockTitle={t(`common:campaign.section_products_title`)}
          blockTitleStyle="fancy"
          blockContentWidth="large"
          products={products}
          withLogo
          withPrice
          withUniverseDescription
          withReducedPrice
        />
      )}

      <DynamicBlock
        __component="ThreeSteps"
        data-testid="three_steps"
        blockTitle={t(`common:information.section_three_steps_title`)}
      />
      <DynamicBlock
        __component="Warranties"
        data-testid="warranties_block"
        blockTitle={t(`common:campaign.section_warranties_title`)}
      />
    </>
  )
}

MultipleCampaignPage.propTypes = {
  data: PropTypes.shape({
    campaign: PropTypes.shape({
      bannerImg: PropTypes.object,
      name: PropTypes.string.isRequired,
      campaignHook: PropTypes.string.isRequired,
      landingPageProducts: PropTypes.array.isRequired,
      coveritsVariant: PropTypes.array.isRequired,
      bannerTemplate: PropTypes.string,
      bannerBgColor: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query MultipleCampaignById($id: String!) {
    campaign(id: { eq: $id }) {
      campaignHook
      name
      bannerTemplate
      bannerBgColor
      bannerImg {
        ...responsiveRemoteImage
      }
      landingPageProducts {
        id
        originalId
        name
        slug
        tunnelSpecificity
        universDescription
        metaDescription
        impactProduct {
          impactId
          variants {
            offerCode
            areaCode
            standardPrice
            offerPrice
          }
        }
        housingType
        universes {
          id
          name
          slug
          primaryColor
          pictoSvg
        }
      }
      coveritsVariant {
        id
        offerCode
        areaCode
        standardPrice
        offerPrice
        campaignEndDate
      }
    }
  }
`
